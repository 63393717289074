import {Box, Flex, Heading, Text} from "@chakra-ui/react";

interface Props {
  label: string
  subLabel: string
}

export const Head = ({label, subLabel}:Props) => {
  return (
    <Flex direction={"column"} alignItems={"center"} color={"text.black"} mb={["56px"]}>
      <Text fontWeight={"600"} fontSize={14}>{subLabel}</Text>
      <Box mt={["10px", "10px", "14px"]} w={"40px"} h={"2px"} bg={"pink"} />
      <Heading mt={["16px", "16px", "24px"]} fontWeight={"600"} fontSize={25} lineHeight={1.5}>{label}</Heading>
    </Flex>
  )
}
