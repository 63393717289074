import React from "react";
import {OrderItemCard as SuspenseComponent} from "pages/orderSuccess/OrderItemCard/OrderItemCard";
import {OrderItemCardSkeleton} from "pages/orderSuccess/OrderItemCard/Skeleton";

interface Props {
  sessionId: string
}

export default function OrderItemCard({sessionId}: Props) {
  return (
    <React.Suspense fallback={<OrderItemCardSkeleton />}>
      <SuspenseComponent sessionId={sessionId} />
    </React.Suspense>
  )
}
