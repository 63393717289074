import { useCallback, useMemo, useState } from "react";
import { CheckoutRequestParams, CheckoutResponseRaw } from "./dto/Checkout";
import { SERVER_DOMAIN } from "./utils";
import useSWR, { useSWRConfig } from "swr";
import { MutatorCallback } from "swr/dist/types";

const URL_PATH = `${SERVER_DOMAIN}/api/v1/marvle/checkout/`;

const fetcher =
  (params: CheckoutRequestParams): MutatorCallback<CheckoutResponseRaw> =>
  (prev) => {
    const sessionId =
      prev && (prev.status === 200 || prev.status === 201)
        ? prev.data.id
        : null;
    const method = sessionId !== null ? "PUT" : "POST";
    const url = sessionId !== null ? `${URL_PATH}${sessionId}/` : URL_PATH;

    return fetch(url, {
      method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
      credentials: "include",
      body: JSON.stringify(params),
    }).then<CheckoutResponseRaw>(async (res) => {
      const result = await res.json();
      if (!res.ok) {
        // エラー文言はとりあえず１種類しか出さない
        const errorMessage = findErrorMessage(result.errors);
        console.log(errorMessage);
        throw new Error(errorMessage);
      }
      return result;
    });
  };

const findErrorMessage = (object: any): string => {
  const array = Array.isArray(object)
    ? object
    : typeof object === "object"
    ? Object.values(object)
    : [object];

  return array.reduce<string>((prev, value) => {
    if (prev !== "") return prev;

    if (typeof value === "string") {
      return value;
    } else if (Array.isArray(value)) {
      if (value.length === 0) return "";
      console.log(value[0], value.length);
      return findErrorMessage(value[0]);
    }
    return findErrorMessage(Object.values(value)[0] ?? []);
  }, "");
};

/**
 * レンタル処理周りのhooks
 */
export const useCheckout = () => {
  const { data, error, mutate } = useSWR<CheckoutResponseRaw, Error>(URL_PATH);
  const { cache } = useSWRConfig();
  const [isLoading, setIsLoading] = useState(false);

  const onCheckout = useCallback(async (params: CheckoutRequestParams) => {
    setIsLoading(true);

    try {
      return await mutate(fetcher(params), {
        populateCache: true,
        revalidate: false,
      });
    } finally {
      setIsLoading(false);
    }
  }, []);

  const clearCache = useCallback(() => {
    cache.delete(URL_PATH);
  }, [cache]);

  const response = useMemo(() => {
    if (data?.status === 201 || data?.status === 200) {
      return data.data;
    }
  }, [data]);

  return {
    data: response,
    mutate: onCheckout,
    isLoading,
    error,
    clearCache,
  };
};
