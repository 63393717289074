import { Box, Heading, HStack, Image, Text, VStack } from "@chakra-ui/react";
import React, { useContext, useEffect } from "react";
import { useGetOrder } from "api/getOrder";
import { MerchantURLContext } from "pages/orderSuccess/Context";
import ReactGA from "react-ga4";
import { Warning } from "components/commons/Warning";

interface Props {
  sessionId: string;
}

export const OrderItemCard = ({ sessionId }: Props) => {
  const { data } = useGetOrder(sessionId, true);
  const { setMerchantUrl } = useContext(MerchantURLContext);

  useEffect(() => {
    setMerchantUrl(data?.merchant.success_url ?? undefined);

    if (data) {
      ReactGA.event("purchase", {
        currency: "JPY",
        transaction_id: data.id, // orderId
        affiliation: data.merchant.name,
        value: data.lines.reduce((p, v) => p + v.price * v.quantity, 0) ?? 0,
        items:
          data.lines.map((v) => ({
            item_id: v.id,
            item_name: v.product.name,
            item_variant: v.product.variant_name,
            price: v.price,
            quantity: v.quantity,
          })) ?? [],
      });
    }
  }, [data?.merchant.success_url, setMerchantUrl]);

  return (
    <VStack
      align={"start"}
      p={"24px"}
      spacing={"24px"}
      boxShadow={"4px 4px 24px rgba(0, 0, 0, 0.08)"}
      w={["100%", "100%", "660px"]}
      flexShrink={0}
      bg={"white"}
      borderRadius={"4px"}
    >
      <VStack spacing={["24px", "24px", "56px"]} align={"start"} w={"100%"}>
        <VStack
          w={"100%"}
          align={"start"}
          padding={"0 16px 16px"}
          h={"fit-content"}
          spacing={"40px"}
        >
          <VStack w={"100%"} align={"start"}>
            <Heading
              fontWeight={"bold"}
              as="h3"
              mb={"24px"}
              color={"text.black"}
              size="md"
            >
              {"注文した商品"}
            </Heading>
            {data?.lines.map((v) => (
              <HStack
                key={v.id}
                align={"start"}
                w={"100%"}
                pb={"16px"}
                borderBottomWidth={1}
                borderStyle={"solid"}
                borderColor={"border.gray"}
              >
                <Image
                  width={"60px"}
                  height={"60px"}
                  objectFit={"contain"}
                  src={v.product.media[0].thumbnail}
                />
                <VStack
                  ml={"16px"}
                  flex={1}
                  gridRowGap={"4px"}
                  color={"text.black"}
                  spacing={"8px"}
                  align={"start"}
                >
                  <Text fontSize={"12px"} noOfLines={3}>
                    {v.product.name}
                  </Text>
                  <Text fontSize={"12px"}>{v.product.variant_name}</Text>
                  <Text fontSize={"11px"}>{`数量: ${v.quantity}`}</Text>
                </VStack>
              </HStack>
            ))}
          </VStack>
          <VStack w={"100%"} align={"start"}>
            <Heading
              fontWeight={"bold"}
              as="h3"
              mb={"24px"}
              color={"text.black"}
              size="md"
            >
              {"お届け・返却日"}
            </Heading>
            {data?.lines.map((v) => (
              <VStack key={v.id} w={"100%"} spacing={"16px"}>
                <HStack
                  w={"100%"}
                  pb={"8px"}
                  borderBottomWidth={1}
                  borderStyle={"solid"}
                  borderColor={"border.gray"}
                  justifyContent={"space-between"}
                >
                  <Text fontSize={"14px"} color={"text.black"}>
                    {"お届け日"}
                  </Text>
                  <Text fontSize={"14px"} color={"text.black"}>
                    {v.start_date}
                  </Text>
                </HStack>
                <HStack
                  w={"100%"}
                  pb={"8px"}
                  borderBottomWidth={1}
                  borderStyle={"solid"}
                  borderColor={"border.gray"}
                  justifyContent={"space-between"}
                >
                  <Text fontSize={"14px"} color={"text.black"}>
                    {"返却日"}
                  </Text>
                  <Text fontSize={"14px"} color={"text.black"}>
                    {v.end_date}
                  </Text>
                </HStack>
              </VStack>
            ))}
          </VStack>
          <Box pt={"16px"}>
            <Text color={"text.sub"} fontSize={"14px"}>
              {
                "※お届け先・お支払い方法に関しては、購入完了メールをご確認ください"
              }
            </Text>
          </Box>
        </VStack>
      </VStack>
      {data?.lines.some(({ product }) => product.note.is_display) && (
        <Warning />
      )}
    </VStack>
  );
};
