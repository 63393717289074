import {Box, Container, Heading, VStack} from "@chakra-ui/react";
import Icon from "components/commons/icons";
import {FlowSectionCard} from "components/section/RentalGuide/FlowSectionCard";
import {CaseSectionCard} from "components/section/RentalGuide/CaseSectionCard";

export default function RentalGuide () {
  return (
    <Box bgColor={"background.green"} padding={["40px 24px", "40px 24px", "80px 20px"]}>
      <VStack justifyContent={"center"} mb={["40px", "40px", "56px"]}>
        <Icon.Beginner />
        <Heading as={"h2"} id={"rental-guide"} color={"primary"} fontWeight={"bold"} fontSize={"30px"}>{"レンタルガイド"}</Heading>
      </VStack>
      <Container maxW='container.lg'>
        <VStack spacing={"40px"}>
          {/*<SupportSectionCard />*/}
          <FlowSectionCard />
          <CaseSectionCard />
        </VStack>
      </Container>
    </Box>
  )
}
