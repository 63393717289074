import React, { useMemo } from "react";
import {
  Box,
  Button,
  Heading,
  HStack,
  Image,
  Select,
  Spacer,
  Text,
  useBoolean,
  VStack,
} from "@chakra-ui/react";
import { GetProductResponse } from "../../api/dto/ProductResponse";
import Icons from "components/commons/icons";
import { useNavigate } from "react-router";

interface Props {
  product: GetProductResponse;
}

export const ProductCard = ({ product }: Props) => {
  const navigate = useNavigate();
  const [isOpen, { toggle }] = useBoolean(false);

  const hasVariants = useMemo(
    () => product.variants.length > 1,
    [product.variants]
  );

  const handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    navigate(`/products/${e.target.value}`);
  };

  return (
    <VStack align={"start"} flexGrow={1}>
      <HStack
        color={"text.black"}
        fontWeight={"medium"}
        direction={["column", "column", "row"]}
        spacing={["16px", "20px", "24px"]}
        alignItems={"flex-start"}
      >
        <Image
          w={["58px", "86px", "160px"]}
          h={["58px", "86px", "160px"]}
          src={product.media[0].thumbnail}
        />
        <VStack spacing={["24px", "24px", "16px"]} alignItems={"flex-start"}>
          <VStack spacing={"8px"} alignItems={"flex-start"}>
            <Heading as={"h2"} fontSize={14} mb={"8px"}>
              {product.name}
            </Heading>
            {hasVariants && (
              <Select
                w={"100%"}
                color={"text.black"}
                defaultValue={product.id}
                onChange={handleOnChange}
              >
                {product.variants.map((variant) => (
                  <option key={variant.id} value={variant.id}>
                    {variant.variant_name}
                  </option>
                ))}
              </Select>
            )}
            <Text fontSize={"13px"}>{`数量：1`}</Text>
          </VStack>
          <VStack margin={"0 -8px"} alignItems={"flex-start"}>
            <Button
              variant={"link"}
              alignItems={"center"}
              color={"primary"}
              onClick={toggle}
            >
              <Text fontSize={"13px"}>{"商品説明"}</Text>
              <Icons.Plus />
            </Button>
            {isOpen && (
              <VStack align={"start"}>
                <Text fontSize={"13px"} color={"text.sub"}>
                  {product.description}
                </Text>
                <Text fontSize={"13px"} color={"text.sub"}>
                  {product.more_description}
                </Text>
              </VStack>
            )}
          </VStack>
        </VStack>
      </HStack>
      <Box
        w={"100%"}
        h={"40px"}
        borderBottomWidth={1}
        borderStyle={"solid"}
        borderColor={"border.gray"}
      />
      <Spacer />
    </VStack>
  );
};
