import { useCheckout } from "api/useCheckout";
import { ScreeningType } from "./types";
import { useEffect, useMemo, useState } from "react";
import { RequestParams } from "components/PurchaseModal/types";

export const useHooks = (params: RequestParams) => {
  const [currentPage, setCurrentPage] = useState<ScreeningType>();
  const { data, isLoading } = useCheckout();

  const screeningStack = useMemo<ScreeningType[]>(() => {
    const stack: ScreeningType[] = [];
    if (data?.required_actions?.tel_screening) stack.push("tel");
    return stack;
  }, [data]);

  // マウント直後のscreeningTypeを設定
  useEffect(() => {
    setCurrentPage(screeningStack[0]);
  }, []);

  const hasNextPage = useMemo(
    () =>
      screeningStack.findIndex((v) => v === currentPage) <
      screeningStack.length - 1,
    [currentPage]
  );

  const isValid = useMemo(
    () => validateScreening(params, screeningStack),
    [data, params]
  );

  return {
    data,
    isLoading,
    currentPage,
    screeningStack,
    isValid,
    hasNextPage,
  };
};

const validateScreening = (
  params: RequestParams,
  stacks: ScreeningType[]
): boolean => {
  if (stacks.includes("tel")) {
    if (!params.screening?.tel_screening.request_approach_time) return false;
  }
  return true;
};
