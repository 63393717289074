import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Image,
  Select,
  Skeleton,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import Header from "../../components/commons/Header";
import Layout from "../../components/Layout";
import Icons from "components/commons/icons";
import React from "react";
import Tag from "components/commons/Tag";

export const ProductMainSkeleton = () => {
  return (
    <Flex
      flexFlow={["column", "column", "row"]}
      w={"100%"}
      gap={["20px", "20px", "40px"]}
    >
      <VStack align={"start"} flexGrow={1}>
        <HStack
          color={"text.black"}
          fontWeight={"medium"}
          direction={["column", "column", "row"]}
          spacing={["16px", "20px", "24px"]}
          alignItems={"flex-start"}
        >
          <Skeleton
            w={["58px", "64px", "160px"]}
            h={["58px", "64px", "160px"]}
          />
          <VStack spacing={"16px"} alignItems={"flex-start"}>
            <VStack spacing={"8px"} alignItems={"flex-start"}>
              <Skeleton width={"100%"} h={"20px"} />
              <Skeleton w={"200px"} h={"14px"} />
            </VStack>
          </VStack>
        </HStack>
        <Box
          w={"100%"}
          h={"32px"}
          borderBottomWidth={1}
          borderStyle={"solid"}
          borderColor={"border.gray"}
        />
        <Spacer />
      </VStack>
      <VStack
        align={"start"}
        p={"24px"}
        spacing={"24px"}
        boxShadow={"4px 4px 24px rgba(0, 0, 0, 0.08)"}
        w={["100%", "100%", "340px"]}
      >
        <VStack align={"start"} spacing={"16px"} w={"100%"}>
          <VStack align={"start"} spacing={"4px"}>
            <Skeleton w={"200px"} h={"18px"} />
            <Skeleton w={"80px"} h={"18px"} />
          </VStack>
          <HStack color={"text.black"} align={"baseline"} spacing={"4px"}>
            <Skeleton w={"120px"} h={"30px"} />
          </HStack>
          <Skeleton w={"100%"} h={"42px"} />
          <Box
            w={"100%"}
            h={["24px"]}
            borderBottom={"1px solid"}
            borderBottomColor={"border.gray"}
          />
          <VStack
            align={"start"}
            spacing={0}
            fontSize={"12px"}
            fontWeight={"400"}
            lineHeight={1.6}
            color={"text.black"}
            whiteSpace={"pre-wrap"}
          >
            <Text>【レンタル期間について】</Text>
            <Text>・商品の到着日が1日目です</Text>
            <Text>・お手元を離れる日が返送日です</Text>
            <Text>・返送日の24時までに発送をお済ませください</Text>
            <Text>
              ・返却が遅れる場合、延長をご希望の場合は事前にカスタマーサポートまでご連絡ください。
            </Text>
            <Text>
              ・返却がなく、お客様へ連絡が取れない場合には延滞料金をご請求させて頂く場合がございます。
            </Text>
          </VStack>
        </VStack>
      </VStack>
    </Flex>
  );
};
