import React from 'react';
import {Route, Routes} from "react-router-dom";
import {Product} from "./pages/product";
import usePageTracking from "./hooks/usePageTracking";
import {OrderSuccess} from "pages/orderSuccess";

export default function Router() {
  usePageTracking()

  return (
    <Routes>
      <Route path={"/products/:id"} element={<Product />} />
      <Route path={"/order/success"} element={<OrderSuccess />} />
    </Routes>
  );
};
