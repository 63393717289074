import React, { useEffect, useState } from "react";
import { ProductMainCard as SuspenseComponent } from "./ProductMainCard";
import { ProductMainSkeleton } from "components/ProductMain/Skeleton";
import { ProductContext } from "components/ProductMain/Context";
import { GetProductResponse } from "api/dto/ProductResponse";
import ReactGA from "react-ga4";

interface Props {
  productId: string;
}

export default function ProductMainCard({ productId }: Props) {
  const [product, setProduct] = useState<GetProductResponse>();

  useEffect(() => {
    if (product?.merchant.id) {
      const title = `${product.name} | ${product.merchant.name} | Marvle Rentalページ`;
      document.title = title;

      ReactGA.send({
        hitType: "pageview",
        page: location.pathname + location.search,
        title,
      });

      ReactGA.event("view_item", {
        currency: "JPY",
        value: product.price.min_price,
        affiliation: product.merchant.name,
        items: [
          {
            item_id: product.id,
            item_name: product.name,
            item_variant: product.variant_name,
            price: product.price,
            quantity: 1,
          },
        ],
      });
    }
  }, [product?.merchant.id]);

  return (
    <ProductContext.Provider value={{ product, setProduct }}>
      <React.Suspense fallback={<ProductMainSkeleton />}>
        <SuspenseComponent productId={productId} />
      </React.Suspense>
    </ProductContext.Provider>
  );
}
