import { ColorModeScript } from "@chakra-ui/react";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import Provider from "./Provider";
import reportWebVitals from "./reportWebVitals";
import Router from "./Router";
import * as serviceWorker from "./serviceWorker";
import { setupWorker } from "msw";
import { handlers } from "mocks/handlers";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

if (process.env.NODE_ENV === "development" && process.env.REACT_APP_WITH_MOCK) {
  const worker = setupWorker();
  worker.start();
  worker.use(...handlers);
}

Sentry.init({
  dsn: "https://16f1b152a8264173bf2c75a6deddd8ed@o4504132979982336.ingest.sentry.io/4504270021984256",
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_ENV ?? "development",

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <ColorModeScript />
    <Provider>
      <Router />
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
