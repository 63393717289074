export enum TelScreeningRequestApproachTime {
  Morning = "morning",
  Afternoon = "afternoon",
  Evening = "evening",
  Anytime = "anytime",
}

export interface CheckoutRequestParams {
  lines: {
    product: string;
    quantity: number;
    start_date: string; // YYYY-MM-DD
    end_date: string; // YYYY-MM-DD
  }[];
  commit: boolean;
  screening?: {
    tel_screening: {
      request_approach_time: TelScreeningRequestApproachTime;
    };
  };
}

export interface CheckoutRequiredActions {
  tel_screening: boolean;
}
export interface Checkout {
  id: string;
  created_at: string;
  updated_at: string;
  lines: {
    id: string;
    product: string;
    quantity: number;
    created_at: string;
    start_date: string; // YYYY-MM-DD
    end_date: string; // YYYY-MM-DD
    price: number;
  }[];
  screening?: {
    id: string;
    tel_screening?: {
      request_approach_time: TelScreeningRequestApproachTime;
      status: string; // TODO: enum等で管理する
    };
  };
  required_actions: CheckoutRequiredActions;
  stripe_checkout_url: string;
}

type PostCheckoutResponseRaw =
  | {
      data: Checkout;
      message: "success";
      status: 201;
    }
  | {
      status: 400 | 401 | 402 | 403 | 404 | 500;
      errors: {
        [key in string]: string | string[];
      };
    };

type PutCheckoutResponseRaw =
  | {
      data: Checkout;
      message: "success";
      status: 200;
    }
  | {
      status: 400 | 401 | 402 | 403 | 404 | 500;
      errors: {
        [key in string]: string | string[];
      };
    };

export type CheckoutResponseRaw =
  | PostCheckoutResponseRaw
  | PutCheckoutResponseRaw;
