import {Box, Text} from "@chakra-ui/react";
import {ReactNode, useMemo} from "react";

interface Props {
  text: string
}

export default function Tag ({text}: Props) {
  return (
    <Box padding={"2px 4px"} borderRadius={"4px"} borderWidth={1} borderStyle={"solid"} borderColor={"border.black"}>
      <Text color={"text.sub"} fontSize={"12px"} fontWeight={"light"}>{text}</Text>
    </Box>
  )
}
