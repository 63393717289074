import SectionCard from "components/commons/SectionCard";
import {Box, Flex, Text, VStack} from "@chakra-ui/react";
import Icon from "components/commons/icons";
import {ReactNode} from "react";
import Balloon, {Direction} from "components/commons/Balloon";

export const CaseSectionCard = () => {
  return (
    <SectionCard>
      <SectionCard.Head label={"7泊8日レンタルの場合"} subLabel={"Case"} />
      <Flex flexDirection={["row", "row", "column"]} justifyContent={["center", "center", "center"]} gap={"16px"} w={"100%"} maxW={["100%", "420px", "740px"]}>
        <Flex flexDirection={["column", "column", "row"]} gap={["6px", "6px", "8px"]} justifyContent={"space-between"} w={"100%"} maxW={["66px", "66px", "100%"]}>
          <DateTile date={1} color={"pink"} />
          <Box h={["auto", "auto", "2px"]} flexGrow={1} bg={"border.gray"} mt={"12px"} />
          <DateTile date={2} />
          <Box h={["auto", "auto", "2px"]} flexGrow={1} bg={"border.gray"} mt={"12px"} />
          <DateTile date={3} />
          <Box h={["auto", "auto", "2px"]} flexGrow={1} bg={"border.gray"} mt={"12px"} />
          <DateTile date={4} />
          <Box h={["auto", "auto", "2px"]} flexGrow={1} bg={"border.gray"} mt={"12px"} />
          <DateTile date={5} />
          <Box h={["auto", "auto", "2px"]} flexGrow={1} bg={"border.gray"} mt={"12px"} />
          <DateTile date={6} />
          <Box h={["auto", "auto", "2px"]} flexGrow={1} bg={"border.gray"} mt={"12px"} />
          <DateTile date={7} />
          <Box h={["auto", "auto", "2px"]} flexGrow={1} bg={"border.gray"} mt={"12px"} />
          <DateTile date={8} color={"purple"} />
        </Flex>
        <Flex flexDirection={["column", "column", "row"]} gap={["6px", "6px", "8px"]} justifyContent={"space-between"} w={"100%"} margin={["-22px 0", "-22px 0", "0"]}>
          <Balloon direction={[Direction.LeftTop, Direction.LeftTop, Direction.TopLeft]}>
            <Text fontWeight={"bold"} fontSize={16} mb={"4px"}>{"お届け日"}</Text>
            <Text fontSize={14}>{"指定した日時に届きます"}</Text>
            <Text fontSize={11} color={"text.sub"}>{"※ 最短9〜12時を指定可能"}</Text>
          </Balloon>
          <Balloon direction={[Direction.LeftBottom, Direction.LeftBottom, Direction.TopRight]} color={"background.purple"}>
            <Text fontWeight={"bold"} fontSize={16} mb={"4px"}>{"返却日"}</Text>
            <Text fontSize={14}>{"発送を済ませたら返却完了です"}</Text>
            <Text fontSize={11} color={"text.sub"}>{"※ 24時までにお手元を離れればOK"}</Text>
            <Text fontSize={11} color={"text.sub"}>{"※ 返却日=必着日ではありません"}</Text>
          </Balloon>
        </Flex>
      </Flex>
    </SectionCard>
  )
}

const SmallCard = ({children}: {children: ReactNode}) => {
  return (
    <VStack textAlign={"center"} fontSize={"14px"} borderWidth={1} borderStyle={"solid"} borderColor={"border.gray"} p={"16px 8px"} pt={["8px", "8px", "16px"]} w={["150px", "160px", "200px"]} spacing={"8px"}>
      <Icon.Check />
      {children}
    </VStack>
  )
}

const DateTile = ({date, color = "#949898"}: {date: number; color?: string}) => {
  return (
    <Box color={"white"} bg={color} borderRadius={4} padding={"0 6px 3px 6px"} textAlign={"center"}>
      <Text as={"span"} fontWeight={500} fontSize={"16px"} lineHeight={1.5}>{date}</Text>
      <Text as={"span"} fontWeight={400} fontSize={"12px"} lineHeight={1.5}>{"日目"}</Text>
    </Box>
  )
}


