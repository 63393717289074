import { RequestHandler, rest } from "msw";
import { Mocks } from "__test__/mocks";
import { SERVER_DOMAIN } from "api/utils";
import dayjs from "dayjs";
import { Checkout, CheckoutRequestParams } from "api/dto/Checkout";

const createCheckoutResponse = (
  requestParams: CheckoutRequestParams,
  checkoutId?: string
): Checkout => {
  const lines = requestParams.lines.map((line) => {
    const product =
      Object.values(Mocks.Product).find((value) => line.product === value.id) ??
      Mocks.Product.Product1;
    const dateDiff = dayjs(line.end_date).diff(dayjs(line.start_date), "day");
    const price =
      product.price.min_price + product.price.additional_fee * dateDiff;
    return {
      ...line,
      id: "mock-checkout-line-id",
      price,
      created_at: "2021-01-01T00:00:00Z",
    };
  });

  // NOTE: Product1だけは電話番号認証が必要にしている
  const requiredActions = {
    tel_screening: lines[0].product === Mocks.Product.Product1.id,
  };

  const screening = requestParams.screening
    ? {
        id: "screening_id",
        tel_screening: requestParams.screening.tel_screening
          ? {
              request_approach_time:
                requestParams.screening.tel_screening.request_approach_time,
              status: "not-started",
            }
          : undefined,
        note: "note",
      }
    : undefined;

  const checkoutURL = requestParams.commit
    ? "/order/success?session_id=mock-sesion-id"
    : "";

  return {
    id: checkoutId ?? `mock-checkout-id-${Math.random()}`,
    lines,
    created_at: "2021-01-01T00:00:00Z",
    updated_at: "2021-01-01T00:00:00Z",
    screening,
    required_actions: requiredActions,
    stripe_checkout_url: checkoutURL,
  };
};

export const checkoutHandlers: RequestHandler[] = [
  rest.post(
    `${SERVER_DOMAIN}/api/v1/marvle/checkout/`,
    async (req, res, ctx) => {
      const request = (await req.json()) as CheckoutRequestParams;
      const data = createCheckoutResponse(request);

      return res(
        ctx.status(201),
        ctx.json({ data, message: "success", status: 201 })
      );
    }
  ),

  rest.put(
    `${SERVER_DOMAIN}/api/v1/marvle/checkout/:checkoutId`,
    async (req, res, ctx) => {
      const { checkoutId } = req.params as { checkoutId: string };
      const request = (await req.json()) as CheckoutRequestParams;

      const data = createCheckoutResponse(request, checkoutId);

      return res(
        ctx.status(200),
        ctx.json({ data, message: "success", status: 200 })
      );
    }
  ),
];
