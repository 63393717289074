import {
  Box, Heading, HStack,
  Skeleton, Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";

export const OrderItemCardSkeleton = () => {
  return (
    <VStack align={"start"} p={"24px"} spacing={"24px"} boxShadow={"4px 4px 24px rgba(0, 0, 0, 0.08)"} w={["100%", "100%", "660px"]} flexShrink={0} bg={"white"} borderRadius={"4px"}>
      <VStack spacing={["24px", "24px", "56px"]} align={"start"} w={"100%"}>
        <VStack
          w={"100%"}
          align={"start"}
          padding={"0 16px 16px"}
          h={"fit-content"}
          spacing={"40px"}
        >
          <VStack w={"100%"} align={"start"}>
            <Heading fontWeight={"bold"} as="h3" mb={"24px"} color={"text.black"} size="md">
              {"注文した商品"}
            </Heading>
            <HStack align={"start"} w={"100%"} pb={"16px"} borderBottomWidth={1} borderStyle={"solid"} borderColor={"border.gray"}>
              <Skeleton w={"60px"} h={"60px"} />
              <VStack ml={"16px"} flex={1} gridRowGap={"4px"} color={"text.black"} spacing={"8px"} align={"start"}>
                <Skeleton w={"100px"} h={"14px"} />
                <Skeleton w={"60px"} h={"14px"} />
                <Skeleton w={"60px"} h={"14px"} />
              </VStack>
            </HStack>
          </VStack>
          <VStack w={"100%"} align={"start"}>
            <Heading fontWeight={"bold"} as="h3" mb={"24px"} color={"text.black"} size="md">
              {"お届け・返却日"}
            </Heading>
            <VStack w={"100%"} spacing={"16px"}>
              <HStack w={"100%"} pb={"8px"} borderBottomWidth={1} borderStyle={"solid"} borderColor={"border.gray"} justifyContent={"space-between"}>
                <Text fontSize={"14px"} color={"text.black"}>{"お届け日"}</Text>
                <Skeleton w={"60px"} h={"16px"} />
              </HStack>
              <HStack w={"100%"} pb={"8px"} borderBottomWidth={1} borderStyle={"solid"} borderColor={"border.gray"} justifyContent={"space-between"}>
                <Text fontSize={"14px"} color={"text.black"}>{"返却日"}</Text>
                <Skeleton w={"60px"} h={"16px"} />
              </HStack>
            </VStack>
          </VStack>
          <Box pt={"16px"}>
            <Text color={"text.sub"} fontSize={"14px"}>{"※お届け先・お支払い方法に関しては、購入完了メールをご確認ください"}</Text>
          </Box>
        </VStack>
      </VStack>
    </VStack>
  );
};
