import React, { useMemo } from "react";
import { Box, Button, HStack, Text, VStack } from "@chakra-ui/react";
import { GetProductResponse } from "api/dto/ProductResponse";
import Tag from "components/commons/Tag";
import { DiscountCard } from "components/ProductMain/DiscountCard";

interface Props {
  product: GetProductResponse;
  handlePurchase: () => void;
}

export const PurchaseCard = ({ product, handlePurchase }: Props) => {
  const title = useMemo(
    () =>
      `${product.price.min_days - 1}泊${product.price.min_days}日レンタル料金`,
    [product.price.min_days]
  );

  const isEmptyStock = useMemo(() => product.stocks.quantity < 1, [product]);

  return (
    <VStack
      align={"start"}
      p={"24px"}
      spacing={"24px"}
      boxShadow={"4px 4px 24px rgba(0, 0, 0, 0.08)"}
      w={["100%", "100%", "340px"]}
      flexShrink={0}
      bg={"white"}
      borderRadius={"4px"}
    >
      <VStack align={"start"} spacing={"16px"} w={"100%"}>
        <VStack align={"start"} spacing={"4px"}>
          <Text
            fontSize={"16px"}
            fontWeight={"700"}
            lineHeight={1.5}
            color={"text.black"}
          >
            {title}
          </Text>
          <Tag text={"往復送料込み "} />
        </VStack>
        <HStack color={"text.black"} align={"baseline"} spacing={"4px"}>
          <Text fontSize={"16px"} fontWeight={400} lineHeight={1.5}>
            {"¥"}
          </Text>
          <Text fontSize={"26px"} fontWeight={700} lineHeight={1.2}>
            {product.price.min_price}
          </Text>
          <Text fontSize={"11px"} fontWeight={400} lineHeight={1.5}>
            {"(税込)"}
          </Text>
        </HStack>
        {product.coupon && (
          <DiscountCard coupon={product.coupon} />
        )}
        <Button
          bg={"primary"}
          color={"white"}
          _hover={{
            opacity: 0.9,
          }}
          _active={{
            opacity: 0.6,
          }}
          w={"100%"}
          onClick={handlePurchase}
          disabled={isEmptyStock}
        >
          {"レンタル手続きへ"}
        </Button>
        {isEmptyStock && (
          <Text fontSize={"12px"} color={"text.error"}>
            {"申し訳ありません。ただいま在庫がありません"}
          </Text>
        )}
        <Box
          w={"100%"}
          h={["24px"]}
          borderBottom={"1px solid"}
          borderBottomColor={"border.gray"}
        />

        <VStack
          align={"start"}
          spacing={0}
          fontSize={"12px"}
          fontWeight={"400"}
          lineHeight={1.6}
          color={"text.black"}
          whiteSpace={"pre-wrap"}
        >
          <Text>【レンタル期間について】</Text>
          <Text>
            ・{product.price.min_days - 1}泊{product.price.min_days}
            日のお試しレンタル
          </Text>
          <Text>・利用期間中は保証の対象です</Text>
          <Text>・返送日の24時までに発送をお済ませください</Text>
          <Text>
            ・返却が遅れる場合、延長をご希望の場合は事前にカスタマーサポートまでご連絡ください。
          </Text>
          <Text>
            ・返却がなく、お客様へ連絡が取れない場合には延滞料金をご請求させて頂く場合がございます。
          </Text>
        </VStack>
      </VStack>
    </VStack>
  );
};
