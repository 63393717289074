import useSWR from "swr";
import { GetProductResponseRaw } from "./dto/ProductResponse";
import { SERVER_DOMAIN } from "./utils";

export const useGetProduct = (productId: string, suspense: boolean = false) => {
  const url = `${SERVER_DOMAIN}/api/v1/marvle/product/${productId}/`;

  const fetcher = (url: string) => {
    return fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    }).then<GetProductResponseRaw>((res) => res.json());
  };

  const { data, error } = useSWR(url, fetcher, { suspense });

  return {
    data: data?.status === 200 ? data.data : undefined,
    error,
  };
};
