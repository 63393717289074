import { Checkbox, ModalBody, Text, VStack } from "@chakra-ui/react";
import { RequestParams, TParamsChangeFn } from "components/PurchaseModal/types";
import { useCallback } from "react";
import { TelScreeningRequestApproachTime } from "api/dto/Checkout";

interface Props {
  params: RequestParams;
  onParamsChange: TParamsChangeFn;
}

const REQUEST_APPROACH_TIME = [
  {
    label: "11:00~13:00",
    value: TelScreeningRequestApproachTime.Morning,
  },
  {
    label: "13:00-15:00",
    value: TelScreeningRequestApproachTime.Afternoon,
  },
  {
    label: "15:00-17:00",
    value: TelScreeningRequestApproachTime.Evening,
  },
  {
    label: "希望なし",
    value: TelScreeningRequestApproachTime.Anytime,
  },
];

export const TelScreening = ({ params, onParamsChange }: Props) => {
  const handleOnChange = useCallback(
    (value: TelScreeningRequestApproachTime) => async () => {
      await onParamsChange({
        screening: {
          ...params.screening,
          tel_screening: {
            request_approach_time: value,
          },
        },
      });
    },
    [params, onParamsChange]
  );

  return (
    <ModalBody>
      <VStack align={"center"} spacing={"40px"}>
        <Text fontSize={"18px"}>電話が可能な時間帯を選択します</Text>
        <VStack
          align={"center"}
          spacing={"24px"}
          maxW={"480px"}
          fontSize={"14px"}
          lineHeight={1.5}
        >
          <Text whiteSpace={"pre-wrap"}>
            {
              "本商品のレンタルはお電話によるご本人様確認の実施をお願いしております。お電話の取りやすい時間帯を下記の中から選択してください。\n注文完了後、電話番号「"
            }
            <b>048-883-4050</b>
            {"」よりご連絡させていただきます。"}
          </Text>
          <VStack align={"start"} w={"100%"}>
            {REQUEST_APPROACH_TIME.map(({ label, value }, i) => (
              <Checkbox
                key={i}
                isChecked={
                  params.screening?.tel_screening.request_approach_time ===
                  value
                }
                onChange={handleOnChange(value)}
                size={"lg"}
                colorScheme={"primarySchema"}
                p={"8px"}
                pb={"16px"}
                w={"100%"}
                borderBottom={"1px solid"}
                borderBottomColor={"border.gray"}
                sx={{
                  "> .chakra-checkbox__label": {
                    display: "inline-flex",
                  },
                }}
              >
                <Text fontSize={14} fontWeight={400}>
                  {label}
                </Text>
              </Checkbox>
            ))}
          </VStack>
        </VStack>
      </VStack>
    </ModalBody>
  );
};
