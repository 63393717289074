import { Box, Container, Flex, Link, Spacer } from "@chakra-ui/react";
import Logo from "components/commons/Logo";
import Icon from "../icons";

export default function Footer() {
  return (
    <Box bg={"white"}>
      <Container maxW="container.lg">
        <Flex
          padding={["56px 24px", "56px 24px", "80px 0"]}
          flexFlow={["column", "row", "row"]}
          justifyContent={["center", "center", "space-between"]}
        >
          <Box>
            <Logo width={200} />
          </Box>
          <Spacer h={["40px", "40px", "unset"]} flex={["unset", 2, 1]} />
          <Flex
            flexFlow={"column wrap"}
            rowGap={"16px"}
            flexGrow={[0, 1, 1]}
            columnGap={"40px"}
            maxH={["unset", "unset", "100px"]}
          >
            <Link
              href="https://support.marvle.jp/tokutei/"
              target={"_blank"}
              fontSize={"13px"}
              lineHeight={1.7}
            >
              {"特定商取引法に基づく表記"}
            </Link>
            <Link
              href="https://support.marvle.jp/terms/"
              target={"_blank"}
              fontSize={"13px"}
              lineHeight={1.7}
            >
              {"利用規約"}
            </Link>
            <Link
              href="https://support.marvle.jp/privacy/"
              target={"_blank"}
              fontSize={"13px"}
              lineHeight={1.7}
            >
              {"プライバシーポリシー"}
            </Link>
            <Link
              href="https://support.marvle.jp/faq/"
              target={"_blank"}
              fontSize={"13px"}
              lineHeight={1.7}
            >
              {"よくあるご質問"}
            </Link>
            <Link
              href="https://support.marvle.jp/contact/"
              target={"_blank"}
              fontSize={"13px"}
              lineHeight={1.7}
            >
              {"お問い合わせ"}
            </Link>
            <Spacer display={["none", "none", "block"]} />
            <Link
              href="https://www.marvle.jp/"
              target={"_blank"}
              fontSize={"13px"}
              lineHeight={1.7}
              isExternal
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
            >
              {"EC事業者の方へ"}
              <Icon.ExternalLink />
            </Link>
            <Link
              href="https://corp.onzo.co.jp/"
              target={"_blank"}
              fontSize={"13px"}
              lineHeight={1.7}
              isExternal
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
            >
              {"運営会社"}
              <Icon.ExternalLink />
            </Link>
            <Spacer display={["none", "none", "block"]} />
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
}
