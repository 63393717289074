import { IconProps } from "components/commons/icons/types";

const path1 = `M13.9793 4V5.31944H18.3268L10.6807 12.9656L11.6109 13.8958L19.2571 6.24965V10.5972H20.5765V4H13.9793Z`;
const path2 = `M5.5 7.8125H11V6.3125H4V20.3125H19V13.3125H17.5V19.018H5.5V7.8125Z`;

export const ExternalLink = ({ size = 24, color = "#4A585E" }: IconProps) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <path d={path1} fill={color} />
    <path d={path2} fill={color} />
  </svg>
);
