import { Box, Checkbox, Link, Text } from "@chakra-ui/react";
import Icon from "components/commons/icons";

interface Props {
  checked: boolean;
  onChange: (isChecked: boolean) => void;
}

export const Agreement = ({ checked, onChange }: Props) => {
  return (
    <Box border={"1px solid #D9DEE3"} p={"16px"} lineHeight={0}>
      <Checkbox
        defaultChecked={checked}
        onChange={(e) => onChange(e.target.checked)}
        size={"lg"}
        colorScheme={"primarySchema"}
        sx={{
          "> .chakra-checkbox__label": {
            display: "inline-flex",
          },
        }}
      >
        <Box
          display={"inline-flex"}
          flex-direction={"row"}
          alignItems={"center"}
          gap={"4px"}
        >
          <ExternalLink href={"https://support.marvle.jp/terms/"}>{"利用規約"}</ExternalLink>
          <ExternalLink href={"https://support.marvle.jp/privacy/"}>{"プライバシーポリシー"}</ExternalLink>
          <Text
            as={"span"}
            fontSize={11}
            fontWeight={400}
            letterSpacing={"0.4px"}
          >
            {"に同意する"}
          </Text>
        </Box>
      </Checkbox>
    </Box>
  );
};

const ExternalLink = ({
  href,
  children,
}: {
  href: string;
  children: string;
}) => {
  return (
    <Link
      display={"inline-flex"}
      alignItems={"center"}
      sx={{ "> svg": { display: "block" } }}
      href={href}
      isExternal
    >
      <Text
        as={"span"}
        fontSize={"11px"}
        fontWeight={700}
        lineHeight={1.5}
        textDecorationLine={"underline"}
        color={"primary"}
      >
        {children}
      </Text>
      <Icon.ExternalLink size={20} color={"#1AD3C1"} />
    </Link>
  );
};
