import {ReactNode} from "react";
import { VStack} from "@chakra-ui/react";
import {Head} from "./Head";

interface Props {
  children: ReactNode
}

const SectionCard = ({children}: Props) => {
  return (
    <VStack w={"100%"} bg={"white"} padding={["40px 16px", "40px 16px", "56px 48px"]} boxShadow={"4px 4px 24px rgba(0,0,0,0.04)"} borderRadius={"4px"}>
      {children}
    </VStack>
  )
}

export default Object.assign(SectionCard, {
  Head
})
