import { Merchant } from "api/dto/ProductResponse";

const Merchant1: Merchant = {
  id: "_MERCHANT_ID_1_",
  name: "ONZO SHOP",
  success_url: null,
  earliest_deliver_date: "2022-10-01",
};

export { Merchant1 };
