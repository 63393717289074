import { Box, BoxProps, useBreakpointValue } from "@chakra-ui/react";
import { ReactNode, useMemo } from "react";

type ColorType = BoxProps["color"];

export enum Direction {
  TopLeft = "TOP_LEFT",
  TopRight = "TOP_RIGHT",
  LeftTop = "LEFT_TOP",
  LeftBottom = "LEFT_BOTTOM",
}

interface Props {
  direction: Direction | Direction[];
  color?: ColorType;
  children: ReactNode;
}

export default function Balloon({
  direction: _direction,
  color = "background.pink",
  children,
}: Props) {
  const direction = useBreakpointValue(
    Array.isArray(_direction) ? _direction : [_direction]
  );
  const [box, before] = useMemo(
    () =>
      direction === Direction.TopLeft
        ? topLeftStyle(color)
        : direction === Direction.TopRight
        ? topRightStyle(color)
        : direction === Direction.LeftTop
        ? leftTopStyle(color)
        : leftBottomStyle(color),
    [color, direction]
  );

  return (
    <Box
      padding={"16px"}
      position={"relative"}
      sx={before}
      borderRadius={"4px"}
      _before={box}
    >
      {children}
    </Box>
  );
}

const bottomLeftStyle = (color: ColorType) =>
  [
    // arrow
    {
      content: '""',
      position: "absolute",
      top: "100%",
      left: "50%",
      marginLeft: "-13px",
      border: "13px solid transparent",
      borderTopColor: color,
    },
    // card
    {
      marginBottom: "13px",
      background: color,
    },
  ] as const;

const topLeftStyle = (color: ColorType) =>
  [
    // arrow
    {
      content: '""',
      position: "absolute",
      top: "-26px",
      left: "24px",
      marginLeft: "-13px",
      border: "13px solid transparent",
      borderColor: "transparent",
      borderBottomColor: color,
    },
    // card
    {
      marginTop: "13px",
      background: color,
    },
  ] as const;

const topRightStyle = (color: ColorType) =>
  [
    // arrow
    {
      content: '""',
      position: "absolute",
      top: "-26px",
      right: "13px",
      marginLeft: "-13px",
      border: "13px solid transparent",
      borderColor: "transparent",
      borderBottomColor: color,
    },
    // card
    {
      marginTop: "13px",
      background: color,
    },
  ] as const;

const leftTopStyle = (color: ColorType) =>
  [
    // arrow
    {
      content: '""',
      position: "absolute",
      top: "24px",
      left: "-13px",
      marginLeft: "-13px",
      border: "13px solid transparent",
      borderColor: "transparent",
      borderRightColor: color,
    },
    // card
    {
      marginLeft: "13px",
      background: color,
    },
  ] as const;

const leftBottomStyle = (color: ColorType) =>
  [
    // arrow
    {
      content: '""',
      position: "absolute",
      bottom: "24px",
      left: "-13px",
      marginLeft: "-13px",
      border: "13px solid transparent",
      borderColor: "transparent",
      borderRightColor: color,
    },
    // card
    {
      marginLeft: "13px",
      background: color,
    },
  ] as const;
