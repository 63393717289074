import { RequestHandler, rest } from "msw";
import { Mocks } from "__test__/mocks";
import { SERVER_DOMAIN } from "api/utils";
import { checkoutHandlers } from "./handlers/checkout";

export const handlers: RequestHandler[] = [
  rest.get(
    `${SERVER_DOMAIN}/api/v1/marvle/product/:productId/`,
    (req, res, ctx) => {
      const { productId } = req.params;
      const product = Object.values(Mocks.Product).find(
        (value) => productId === value.id
      );

      if (product) {
        return res(
          ctx.status(200),
          ctx.json({ data: product, message: "success", status: 200 })
        );
      }

      return res(ctx.status(404), ctx.json({ message: "Not Found" }));
    }
  ),

  ...checkoutHandlers,

  rest.get(
    `${SERVER_DOMAIN}/api/v1/marvle/order/:sessionId`,
    (req, res, ctx) => {
      const { sessionId } = req.params;
      const order = {
        id: sessionId,
        merchant: Mocks.Marchant.Merchant1,
        lines: [
          {
            id: "mock-order-line-id",
            product: Mocks.Product.Product1,
            quantity: 1,
            start_date: "2021-01-01",
            end_date: "2021-01-01",
            price: 1000,
          },
        ],
        created_at: "2021-01-01T00:00:00Z",
      };

      if (order) {
        return res(
          ctx.status(200),
          ctx.json({ data: order, message: "success", status: 200 })
        );
      }

      return res(ctx.status(404), ctx.json({ message: "Not Found" }));
    }
  ),
];
