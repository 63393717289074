import {
  Box,
  Image,
  VStack,
} from "@chakra-ui/react";
import Header from "components/commons/Header";
import Layout from "components/Layout";
import React, {useCallback, useEffect} from "react";
import RentalGuide from "components/section/RentalGuide";
import Footer from "components/commons/Footer";
import ProductMainCard from "components/ProductMain";
import {PageHeader} from "pages/product/PageHeader";
import {useParams} from "react-router-dom";

export const Product = () => {
  const params = useParams();

  useEffect(() => {
    if (typeof params.id !== "string") throw new Error(`productId not found`)
  }, [params])

  return (
    <>
      <Header />
      <Box overflow={"hidden"}>
        <Layout zIndex={0}>
          <VStack spacing={["24px", "24px", "40px"]} align={"start"} w={"100%"} mb={"24px"} zIndex={1}>
            <PageHeader />
            <ProductMainCard productId={params.id as string} />
          </VStack>
          <Box position={"absolute"} top={0} left={0} right={0} bottom={0} zIndex={-1}>
            <Image position={"absolute"} left={["-200px", "-280px", "-450px"]} bottom={["-160px", "-200px", "-240px"]} src={"/images/products/bgImage1.png"} />
            <Image position={"absolute"} right={["-200px", "-260px", "-680px"]} top={["-160px", "-360px", "-480px"]} src={"/images/products/bgImage2.png"} />
          </Box>
        </Layout>
      </Box>
      <RentalGuide />
      <Footer />
    </>
  );
};
