import { Flex, Text, VStack } from "@chakra-ui/react";
import Icon from "../icons";

export const Warning = () => {
  return (
    <Flex
      p={"24px"}
      w={"100%"}
      bg={"#FFEDF7"}
      border={"1px solid #FFDFF1"}
      borderRadius={4}
      direction={["column", "column", "row"]}
      gap={"24px"}
    >
      <Flex gap={"8px"} alignItems={"center"} flexShrink={0}>
        <Icon.ExclamationCircle />
        <Text
          fontWeight={700}
          fontSize={"16px"}
          lineHeight={"22px"}
          color={"text.black"}
        >
          {"注意事項"}
        </Text>
      </Flex>
      <VStack
        color={"text.black"}
        fontWeight={600}
        fontSize={"14px"}
        alignItems={"flex-start"}
      >
        <Text>
          {"本商品は"}
          <Text display={"inline"} color={"text.error"}>
            {"お電話による本人確認"}
          </Text>
          {"の実施をお願いしております。あらかじめご了承ください。"}
        </Text>
        <Text>
          {"実施を行っていただけないお客様や本人確認の判定結果によって"}
          <Text display={"inline"} color={"text.error"}>
            {"キャンセルさせていただく"}
          </Text>
          {"場合がございます。"}
        </Text>
      </VStack>
    </Flex>
  );
};
