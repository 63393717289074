import {Container, ContainerProps} from "@chakra-ui/react";
import { ReactNode } from "react";

type Props = Omit<ContainerProps, "maxW" | "color" | "padding" | "position"> & {
  children: ReactNode;
}

export default function Layout({children, ...props}: Props) {
  return (
    <Container maxW={"container.lg"} color={"white"} padding={"32px 24px"} position={"relative"} {...props}>
      {children}
    </Container>
  );
}
