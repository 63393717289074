import { GetProductResponse } from "api/dto/ProductResponse";
import { Merchant1 } from "./Marchant";

const Product1: GetProductResponse = {
  id: "_PRODUCT_ID_1_",
  merchant: Merchant1,
  created_at: "2021-05-20T07:00:00.000000Z",
  updated_at: "2021-05-20T07:00:00.000000Z",
  name: "かわいい猫",
  description: "猫です。かわいいです。癒しです。猫は最高。",
  more_description:
    "大事なことなのでもう一度。猫です。かわいいです。癒しです。猫は最高。",
  media: [
    {
      image: "https://placekitten.com/300/200",
      thumbnail: "https://placekitten.com/200/200",
      big: "https://placekitten.com/1200/600",
      middle: "https://placekitten.com/600/300",
      small: "https://placekitten.com/300/200",
    },
  ],
  price: {
    min_days: 7,
    max_days: 40,
    min_price: 10000,
    additional_fee: 100,
  },
  variant_name: "魚好きなかわいい猫",
  variants: [
    {
      id: "_PRODUCT_ID_1_",
      variant_name: "魚好きなかわいい猫",
      stocks: {
        quantity: 1000,
      },
    },
    {
      id: "_PRODUCT_ID_2_",
      variant_name: "魚嫌いなかわいい猫",
      stocks: {
        quantity: 6,
      },
    },
    {
      id: "_PRODUCT_ID_3_",
      variant_name: "肉が好きなかわいい猫",
      stocks: {
        quantity: 0,
      },
    },
  ],
  note: {
    is_display: true,
  },
  stocks: {
    quantity: 1000,
  },
  coupon: {
    discount_amount: 1000,
    discount_unit: "JPY",
  }
};

const Product2: GetProductResponse = {
  id: "_PRODUCT_ID_2_",
  merchant: Merchant1,
  created_at: "2021-05-20T07:00:00.000000Z",
  updated_at: "2021-05-20T07:00:00.000000Z",
  name: "かわいい猫",
  description: "猫です。かわいいです。癒しです。猫は最高。",
  more_description:
    "大事なことなのでもう一度。猫です。かわいいです。癒しです。猫は最高。",
  media: [
    {
      image: "https://placekitten.com/300/200",
      thumbnail: "https://placekitten.com/200/200",
      big: "https://placekitten.com/1200/600",
      middle: "https://placekitten.com/600/300",
      small: "https://placekitten.com/300/200",
    },
  ],
  price: {
    min_days: 7,
    max_days: 40,
    min_price: 10000,
    additional_fee: 100,
  },
  variant_name: "魚嫌いなかわいい猫",
  variants: [
    {
      id: "_PRODUCT_ID_1_",
      variant_name: "魚好きなかわいい猫",
      stocks: {
        quantity: 1000,
      },
    },
    {
      id: "_PRODUCT_ID_2_",
      variant_name: "魚嫌いなかわいい猫",
      stocks: {
        quantity: 6,
      },
    },
    {
      id: "_PRODUCT_ID_3_",
      variant_name: "肉が好きなかわいい猫",
      stocks: {
        quantity: 0,
      },
    },
  ],
  note: {
    is_display: false,
  },
  stocks: {
    quantity: 6,
  },
  coupon: {
    discount_amount: 1000,
    discount_unit: "JPY",
  }
};

const Product3: GetProductResponse = {
  id: "_PRODUCT_ID_3_",
  merchant: Merchant1,
  created_at: "2021-05-20T07:00:00.000000Z",
  updated_at: "2021-05-20T07:00:00.000000Z",
  name: "かわいい猫",
  description: "猫です。かわいいです。癒しです。猫は最高。",
  more_description:
    "大事なことなのでもう一度。猫です。かわいいです。癒しです。猫は最高。",
  media: [
    {
      image: "https://placekitten.com/300/200",
      thumbnail: "https://placekitten.com/200/200",
      big: "https://placekitten.com/1200/600",
      middle: "https://placekitten.com/600/300",
      small: "https://placekitten.com/300/200",
    },
  ],
  price: {
    min_days: 7,
    max_days: 40,
    min_price: 10000,
    additional_fee: 100,
  },
  variant_name: "肉が好きなかわいい猫",
  variants: [
    {
      id: "_PRODUCT_ID_1_",
      variant_name: "魚好きなかわいい猫",
      stocks: {
        quantity: 1000,
      },
    },
    {
      id: "_PRODUCT_ID_2_",
      variant_name: "魚嫌いなかわいい猫",
      stocks: {
        quantity: 6,
      },
    },
    {
      id: "_PRODUCT_ID_3_",
      variant_name: "肉が好きなかわいい猫",
      stocks: {
        quantity: 0,
      },
    },
  ],
  note: {
    is_display: true,
  },
  stocks: {
    quantity: 0,
  },
  coupon: {
    discount_amount: 1000,
    discount_unit: "JPY",
  }
};

export { Product1, Product2, Product3 };
