import {
  Box,
  Divider,
  Flex,
  Heading,
  HStack,
  Image,
  InputGroup,
  InputRightElement,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  Spacer,
  Text,
  VStack,
  Container,
  Button,
  ModalFooter,
} from "@chakra-ui/react";
import { RangeDatepicker } from "chakra-dayzed-datepicker";
import dayjs from "dayjs";
import { useCallback, useMemo, useState } from "react";
import { GetProductResponse } from "api/dto/ProductResponse";
import Icon from "components/commons/icons";
import { Agreement } from "components/PurchaseModal/Agreement";
import { useCheckout } from "api/useCheckout";
import { RequestParams, TPageNavigation, TParamsChangeFn } from "../types";

interface Props {
  product: GetProductResponse;
  params: RequestParams;
  setCurrentPage: TPageNavigation;
  onParamsChange: TParamsChangeFn;
  onSubmit: () => Promise<void>;
  onClose: () => void;
}

export const SelectSchedule = ({
  product,
  params,
  setCurrentPage,
  onParamsChange,
  onSubmit,
  onClose,
}: Props) => {
  const { data, isLoading, error } = useCheckout();
  const [selectedDates, setSelectedDates] = useState<Date[]>([
    params.startDate.toDate(),
    params.endDate.toDate(),
  ]);

  // 最短レンタル可能日
  const recently = useMemo(
    () => dayjs(product.merchant.earliest_deliver_date),
    [product.merchant.earliest_deliver_date]
  );

  const maxDate = useMemo(
    () =>
      dayjs(params.startDate)
        .add(Math.min(product.price.max_days - 1, 90), "days")
        .toDate(),
    [params.startDate, product.price.max_days]
  );

  const price = useMemo(
    () =>
      data?.lines.map((line) => line.price).reduce((a, b) => a + b, 0) ?? NaN,
    [data]
  );

  const handleOnChange = useCallback(
    async (dates: Date[]) => {
      setSelectedDates(dates);
      if (dates.length < 2) return;

      await onParamsChange({
        startDate: dayjs(dates[0]),
        endDate: dayjs(dates[1]),
      });
    },
    [onParamsChange]
  );

  const handleOnAgreement = useCallback(
    async (agreement: boolean) => {
      await onParamsChange({ agreement });
    },
    [onParamsChange]
  );

  const formatter = new Intl.NumberFormat("ja-JP");

  const rentalDateCount = useMemo(() => {
    return dayjs(params.endDate).diff(params.startDate, "day");
  }, [params]);

  const isNeedScreening = useMemo(
    () => Object.values(data?.required_actions ?? {}).includes(true),
    [data]
  );

  const handleOnSubmit = useCallback(
    async () =>
      isNeedScreening ? setCurrentPage("screening") : await onSubmit(),
    [isNeedScreening, onSubmit, setCurrentPage]
  );

  return (
    <ModalContent>
      <ModalHeader textAlign={"center"}>
        <Text fontSize={"2xl"}>{"日程を選択"}</Text>
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Flex flexDir={["column", "column", "row"]} gap={["20px", "40px"]}>
          <Box
            w={["100%", "100%", "280px"]}
            padding={"0 16px 16px"}
            h={"fit-content"}
          >
            <Heading fontWeight={"bold"} as="h3" size="sm">
              {"カート内の商品"}
            </Heading>
            <Flex mt={"24px"}>
              <Image
                width={"60px"}
                height={"60px"}
                objectFit={"contain"}
                src={product.media[0].image}
              />
              <VStack
                ml={"16px"}
                flex={1}
                gridRowGap={"4px"}
                color={"text.black"}
                spacing={"8px"}
                align={"start"}
              >
                <Text fontSize={"12px"} fontWeight={700} noOfLines={3}>
                  {product.name}
                </Text>
                <VStack align={"start"} spacing={"4px"}>
                  {product.variants.length > 0 && (
                    <Text fontSize={"12px"}>{product.variant_name}</Text>
                  )}
                  <Text fontSize={"11px"}>{"数量: 1"}</Text>
                </VStack>
              </VStack>
            </Flex>
            <Box h={"24px"} />
            <Divider borderColor={"border.gray"} />
          </Box>
          <Flex
            flex={1}
            padding={"16px 20px 40px"}
            boxShadow={"md"}
            borderRadius={"md"}
            flexFlow={"column"}
            gap={4}
          >
            <Box
              bg={"background.green"}
              padding={"16px"}
              borderRadius={"4px"}
              fontSize={"14px"}
            >
              <Flex gap={"24px"} direction={["column", "row", "row"]}>
                <Flex
                  flex={1}
                  justifyContent={"space-between"}
                  pb={"8px"}
                  borderBottom={"1px"}
                  borderColor={"border.gray"}
                >
                  <Text>{"お届け日"}</Text>
                  <Text>{`${dayjs(params.startDate).format(
                    "YYYY/MM/DD"
                  )}`}</Text>
                </Flex>
                <Flex
                  flex={1}
                  justifyContent={"space-between"}
                  borderBottom={"1px"}
                  borderColor={"border.gray"}
                >
                  <Text>{"返却日"}</Text>
                  <Text>{`${dayjs(params.endDate).format("YYYY/MM/DD")}`}</Text>
                </Flex>
              </Flex>
              <Flex
                flex={1}
                mt={"24px"}
                justifyContent={"space-between"}
                borderBottom={"1px"}
                borderColor={"border.gray"}
                alignItems={"center"}
              >
                <Text>{"レンタル料金"}</Text>
                <HStack spacing={0}>
                  <Text
                    fontSize={["16px", "20px", "2xl"]}
                    color={"pink"}
                    fontWeight={"bold"}
                  >
                    {`${Number.isNaN(price) ? `-` : formatter.format(price)}円`}
                  </Text>
                  {rentalDateCount !== undefined && (
                    <Text
                      fontSize={["12px", "16px", "xl"]}
                      color={"pink"}
                      fontWeight={"bold"}
                    >
                      {`（${rentalDateCount}泊${rentalDateCount + 1}日）`}
                    </Text>
                  )}
                </HStack>
              </Flex>
            </Box>
            <Spacer />
            <Flex
              padding={"24px 0"}
              borderBottom={"1px solid"}
              borderColor={"gray.200"}
              alignItems={["flex-start", "flex-start", "center"]}
              flexFlow={["column", "column", "row"]}
              gap={"4px"}
            >
              <Flex
                gap={"12px"}
                flexShrink={0}
                w={"160px"}
                alignItems={"center"}
              >
                <Text fontSize={["13px", "13px", "14px"]}>
                  {"レンタル日程"}
                </Text>
                <Text fontSize={["11px", "11px", "12px"]} color={"pink"}>
                  {"必須"}
                </Text>
              </Flex>
              <InputGroup>
                <RangeDatepicker
                  selectedDates={selectedDates}
                  onDateChange={handleOnChange}
                  minDate={recently.toDate()}
                  maxDate={maxDate}
                  configs={{
                    dateFormat: "yyyy/M/d",
                    monthNames: [...new Array(12)].map((_, i) => `${i + 1}月`),
                    dayNames: ["日", "月", "火", "水", "木", "金", "土"],
                  }}
                  propsConfigs={{
                    inputProps: { size: ["md", "md", "lg"] },
                    dayOfMonthBtnProps: {
                      defaultBtnProps: {
                        _hover: {
                          background: "#A4ECDF",
                        },
                      },
                      isInRangeBtnProps: {
                        background: "primary",
                        color: "text.white",
                      },
                      selectedBtnProps: {
                        background: "primary",
                        color: "text.white",
                      },
                      todayBtnProps: {
                        color: "primary",
                      },
                    },
                  }}
                />
                <InputRightElement h={"100%"}>
                  <Icon.Calender />
                </InputRightElement>
              </InputGroup>
            </Flex>
            {error !== null && <Text color={"red.600"}>{error?.message}</Text>}
            <Box padding={"16px 0"}></Box>
            <Agreement
              checked={params.agreement}
              onChange={handleOnAgreement}
            />
          </Flex>
        </Flex>
      </ModalBody>
      <ModalFooter boxShadow={"4px -4px 20px rgba(0, 0, 0, 0.05)"} mt={16}>
        <Container maxW={["100%", "100%", "2xl"]}>
          <Flex
            padding={0}
            gap={[4, 4, 8]}
            flexFlow={["column-reverse", "column-reverse", "row"]}
          >
            <Button
              w={"100%"}
              bg={"gray.400"}
              color={"white"}
              _hover={{
                opacity: 0.9,
              }}
              _active={{
                opacity: 0.6,
              }}
              size={"lg"}
              onClick={onClose}
            >
              {"戻る"}
            </Button>
            <Button
              bg={"primary"}
              color={"white"}
              _hover={{
                opacity: 0.9,
              }}
              _active={{
                opacity: 0.6,
              }}
              size={"lg"}
              w={"100%"}
              onClick={handleOnSubmit}
              isLoading={isLoading}
              disabled={!params.agreement || isLoading || !!error}
            >
              {isNeedScreening ? "次へ" : "お支払いへ"}
            </Button>
          </Flex>
        </Container>
      </ModalFooter>
    </ModalContent>
  );
};
