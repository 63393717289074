import React, { useCallback, useContext, useEffect } from "react";
import { Flex, useDisclosure } from "@chakra-ui/react";
import { ProductCard } from "components/ProductMain/ProductCard";
import { PurchaseCard } from "components/ProductMain/PurchaseCard";
import { PurchaseModal } from "components/PurchaseModal";
import { useGetProduct } from "api/getProduct";
import ReactGA from "react-ga4";
import { ProductContext } from "components/ProductMain/Context";
import { Warning } from "components/commons/Warning";

interface Props {
  productId: string;
}

export const ProductMainCard = ({ productId }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { setProduct } = useContext(ProductContext);

  const { data } = useGetProduct(productId, true);

  useEffect(() => {
    if (data) setProduct(data);
  }, [data]);

  const handleOpenModal = useCallback(() => {
    if (!data) return;

    ReactGA.event("add_to_cart", {
      currency: "JPY",
      value: data.price ?? 0,
      affiliation: data.merchant.name,
      items: [
        {
          item_id: data.id,
          item_name: data.name,
          item_variant: data.variant_name,
          price: data.price,
          quantity: 1,
        },
      ],
    });
    onOpen();
  }, [data, onOpen]);

  if (!data) return null;

  return (
    <>
      <Flex
        position={"relative"}
        flexFlow={["column", "column", "row"]}
        w={"100%"}
        gap={["20px", "28px", "40px"]}
      >
        <ProductCard product={data} />
        <PurchaseCard product={data} handlePurchase={handleOpenModal} />
      </Flex>
      {data.note.is_display && <Warning />}
      <PurchaseModal isOpen={isOpen} product={data} onClose={onClose} />
    </>
  );
};
