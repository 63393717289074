import { GetProductResponse } from "api/dto/ProductResponse";
import { useCallback, useMemo, useState } from "react";
import { useCheckout } from "api/useCheckout";
import { RequestParams, TParamsChangeFn } from "./types";
import dayjs from "dayjs";
import ReactGA from "react-ga4";

export const useHooks = (product: GetProductResponse) => {
  const { mutate } = useCheckout();
  const recently = useMemo(
    () => dayjs(product.merchant.earliest_deliver_date),
    [product.merchant.earliest_deliver_date]
  );
  const [requestParams, setRequestParams] = useState<RequestParams>({
    product: product.id,
    quantity: 1,
    startDate: recently,
    endDate: recently.add(product.price.min_days - 1, "days"),
    commit: false,
    agreement: false,
  });

  /**
   * リクエスパラメタの更新が発生したら、stateの更新とcheckout(no-commit)の実行を行う
   * @param params
   */
  const handleParamsChange: TParamsChangeFn = useCallback(
    async (params) => {
      const newParams: RequestParams = {
        ...requestParams,
        ...params,
      };
      setRequestParams(newParams);

      if (dayjs(newParams.endDate).diff(newParams.startDate, "day") < 0) return;

      const response = await mutate({
        lines: [
          {
            product: newParams.product,
            quantity: 1,
            start_date: newParams.startDate.format("YYYY-MM-DD"),
            end_date: newParams.endDate.format("YYYY-MM-DD"),
          },
        ],
        commit: false,
        screening: params.screening,
      });

      if (response && response.status === 201) {
        ReactGA.event("add_shipping_info", {
          currency: "JPY",
          transaction_id: response?.data.id,
          affiliation: product.merchant.name,
          value:
            response?.data.lines.reduce(
              (p, v) => p + v.price * v.quantity,
              0
            ) ?? 0,
          items: [
            {
              item_id: product.id,
              item_name: product.name,
              item_variant: product.variant_name,
              price: product.price,
              quantity: 1,
            },
          ], // NOTE: 将来的には複数商品に対応する必要がある
        });
      }
      return response;
    },
    [product, requestParams, mutate]
  );

  const handleOnCheckout = useCallback(async () => {
    const response = await mutate({
      lines: [
        {
          product: requestParams.product,
          quantity: 1,
          start_date: requestParams.startDate.format("YYYY-MM-DD"),
          end_date: requestParams.endDate.format("YYYY-MM-DD"),
        },
      ],
      commit: true,
      screening: requestParams.screening,
    });

    if (response?.status === 201 || response?.status === 200) {
      const data = response.data;

      ReactGA.event("begin_checkout", {
        currency: "JPY",
        transaction_id: data.id,
        affiliation: product.merchant.name,
        value: data.lines.reduce((p, v) => p + v.price * v.quantity, 0) ?? 0,
        items: [
          {
            item_id: product.id,
            item_name: product.name,
            item_variant: product.variant_name,
            price: product.price,
            quantity: 1,
          },
        ], // NOTE: 将来的には複数商品に対応する必要がある
      });

      window.location.href = response.data.stripe_checkout_url;
    }
  }, [product, requestParams, mutate]);

  return {
    requestParams,
    handleParamsChange,
    handleOnCheckout,
  };
};
