import {Text, Heading, Link, VStack, Flex} from "@chakra-ui/react";
import Icon from "components/commons/icons";
import {useNavigate} from "react-router";

export const PageHeader = () => {
  const navigate = useNavigate()

  const handleBack = () => {
    navigate(-1)
  }

  return (
    <VStack spacing={"24px"} alignItems={"left"} color={"text.black"} w={"100%"}>
      <Link onClick={handleBack} fontSize={"14px"} color={"text.sub"} lineHeight={1.7} isExternal display={"flex"} flexDirection={"row"} alignItems={"center"}>
        <Icon.RightArrow />{"前のページに戻る"}
      </Link>
      <Heading textAlign={"left"}  fontSize={["26px", "28px", "32px"]}>{"レンタルカート"}</Heading>
      <Flex bg={"background.green"} padding={"16px"} w={"100%"} borderRadius={"4px"} gap={["0", "0", "16px"]} direction={["column", "column", "row"]}>
        <Text as={"span"} fontSize={"14px"}>{"初めてレンタルされる方はこちらをご確認ください"}</Text>
        <Link href={"#rental-guide"} display={"inline-flex"} alignItems={"center"} fontSize={"13px"} fontWeight={"bold"} color={"primary"}>{"レンタルガイド"}<Icon.DownArrow color={"#1AD3C1"} /></Link>
      </Flex>
    </VStack>
  )
}
