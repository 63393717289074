import SectionCard from "components/commons/SectionCard";
import {Grid, GridItem, Text, VStack, Image} from "@chakra-ui/react";

const STEP_CONTENTS: StepCardProps[] = [
  {
    step: 1,
    title: "利用の申込み",
    image: '/images/products/FlowStep1.png',
    description: "日付を指定して予約完了！\nご指定のご予約日に商品が届きます"
  },
  {
    step: 2,
    title: "商品の受け取り",
    image: '/images/products/FlowStep2.png',
    description: "ご指定の日時にレンタル商品が届きます！\n自宅以外へのお届けもOK"
  },
  {
    step: 3,
    title: "アイテムを使う",
    image: '/images/products/FlowStep3.png',
    description: "レンタル最終日の24時まで、期間いっぱい使い倒せます\n利用期間中はスタッフから特別なレクチャー動画やコンテンツを配信！\nお店のスタッフと一緒に商品をお試し頂けます"
  },
  {
    step: 4,
    title: "返す または 購入",
    image: '/images/products/FlowStep4.png',
    description: "集荷依頼やコンビニのレジで返却完了！\n返さずそのまま購入も選べます",
    note: "※ 対象外の商品もあります"
  }
]

export const FlowSectionCard = () => {
  return (
    <SectionCard>
      <SectionCard.Head label={"注文から返却までの流れ"} subLabel={"Flow"} />
      <VStack w={"100%"} padding={["0", "0", "0 72px"]} spacing={"40px"}>
        {STEP_CONTENTS.map((props, index) => (
          <StepCard key={index} {...props} />
        ))}
      </VStack>
    </SectionCard>
  )
}

interface StepCardProps {
  step: number;
  title: string;
  image: string;
  description: string;
  note?: string;
}

const StepCard = ({step, title, image, description, note}: StepCardProps) => {
  return (
    <Grid
      w={"100%"}
      templateRows={['repeat(5, 1fr)', 'repeat(5, 1fr)', 'repeat(4, 1fr)']}
      templateColumns={['repeat(1, 1fr)','repeat(1, 1fr)','repeat(2, 1fr)']}
      gap={[2, 2, "16px 40px" ]}
    >
      <GridItem colSpan={1} colStart={[1, 1, 2]}>
        <StepTitle step={step} title={title} />
      </GridItem>
      <GridItem rowSpan={[3, 3, 4]} colSpan={1} rowStart={[2, 2, 1]}>
        <Image src={image} maxH={["158px", "216px", "100%"]} alt={title} margin={"0 auto"} />
      </GridItem>
      <GridItem rowSpan={[1, 1, 2]} colSpan={1}>
        <Text fontSize={14} fontWeight={400} textAlign={["center", "center", "left"]} lineHeight={1.5} whiteSpace={"pre-wrap"}>{description}</Text>
        {note && <Text fontSize={12} mt={["4px", "4px", "16px"]} fontWeight={400} textAlign={["center", "center", "left"]} lineHeight={1.5} color={"text.sub"}>{note}</Text>}
      </GridItem>
    </Grid>
  )
}

const StepTitle = ({step, title}: {step: number; title: string}) => {
  return (
    <VStack spacing={"8px"} alignItems={["center", "center", "flex-start"]}>
      <Text fontFamily={"Popping"} fontWeight={"600"} fontSize={"16px"} lineHeight={"14px"} color={"pink"}>{`STEP${step}`}</Text>
      <Text fontWeight={"700"} fontSize={"20px"} lineHeight={1.6} letterSpacing={"0.4px"}>{title}</Text>
    </VStack>
  )
}
