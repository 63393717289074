import {
  Button,
  Container,
  Flex,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
} from "@chakra-ui/react";
import { RequestParams, TParamsChangeFn } from "components/PurchaseModal/types";
import { useHooks } from "./useHooks";
import { TelScreening } from "./TelScreening";

interface Props {
  params: RequestParams;
  onParamsChange: TParamsChangeFn;
  onSubmit: () => Promise<void>;
  onBack: () => void;
}

export const Screening = ({
  params,
  onParamsChange,
  onSubmit,
  onBack,
}: Props) => {
  const { currentPage, isValid, isLoading, hasNextPage } = useHooks(params);

  const handleOnBack = () => {
    // TODO: tel以外の認証方法ができたら、ここにページ戻す処理を書く
    onBack();
  };

  return (
    <ModalContent>
      <ModalHeader textAlign={"center"}>
        <Text fontSize={"2xl"}>{"ご本人様情報の確認"}</Text>
      </ModalHeader>
      <ModalCloseButton />
      {currentPage === "tel" ? (
        <TelScreening params={params} onParamsChange={onParamsChange} />
      ) : null}
      <ModalFooter boxShadow={"4px -4px 20px rgba(0, 0, 0, 0.05)"} mt={16}>
        <Container maxW={["100%", "100%", "2xl"]}>
          <Flex
            padding={0}
            gap={[4, 4, 8]}
            flexFlow={["column-reverse", "column-reverse", "row"]}
          >
            <Button
              w={"100%"}
              bg={"gray.400"}
              color={"white"}
              _hover={{
                opacity: 0.9,
              }}
              _active={{
                opacity: 0.6,
              }}
              size={"lg"}
              onClick={handleOnBack}
            >
              {"戻る"}
            </Button>
            <Button
              bg={"primary"}
              color={"white"}
              _hover={{
                opacity: 0.9,
              }}
              _active={{
                opacity: 0.6,
              }}
              size={"lg"}
              w={"100%"}
              onClick={onSubmit}
              isLoading={isLoading}
              disabled={!isValid}
            >
              {hasNextPage ? "次へ" : "お支払いへ"}
            </Button>
          </Flex>
        </Container>
      </ModalFooter>
    </ModalContent>
  );
};
