import { extendTheme } from "@chakra-ui/react";

const breakpoints = {
  sm: "30em",
  md: "52em",
  lg: "62em",
  xl: "80em",
  "2xl": "96em",
};

const fonts = {
  head: "'Noto Sans JP', sans-serif",
  body: "'Noto Sans JP', sans-serif",
};

export const theme = extendTheme({
  colors: {
    primary: "#1AD3C1",
    pink: "#FF6BC1",
    purple: "#7A6DFF",
    gray: {
      400: "#999999",
    },
    border: {
      green: "#E2F0EF",
      gray: "#E3E8E9",
    },
    background: {
      green: "#F4FEFF",
      pink: "#FFEDF7",
      purple: "#F3EEFF",
    },
    text: {
      black: "#172226",
      sub: "#4A585E",
      white: "#FFFFFF",
      error: "#FF5353",
    },

    primarySchema: {
      500: "#1AD3C1",
    },
  },
  fonts,
  breakpoints,
});
