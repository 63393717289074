import { Beginner } from "components/commons/icons/Beginner";
import { Check } from "components/commons/icons/Check";
import { ExternalLink } from "components/commons/icons/ExternalLink";
import { RightArrow } from "components/commons/icons/RightArrow";
import { DownArrow } from "components/commons/icons/DownArrow";
import { Plus } from "components/commons/icons/Plus";
import { Calender } from "components/commons/icons/Calender";
import { LeftArrow } from "components/commons/icons/LeftArrow";
import { ExclamationCircle } from "components/commons/icons/ExclamationCircle";

const Icon = {
  Beginner,
  Check,
  LeftArrow,
  RightArrow,
  DownArrow,
  ExternalLink,
  Plus,
  Calender,
  ExclamationCircle,
} as const;

export default Icon;
