import { Modal, ModalOverlay } from "@chakra-ui/react";
import { GetProductResponse } from "api/dto/ProductResponse";
import { useHooks } from "./useHooks";
import { SelectSchedule } from "components/PurchaseModal/SelectSchedule";
import { Screening } from "components/PurchaseModal/Screening";
import { useCallback, useEffect, useState } from "react";
import { ModalPage } from "components/PurchaseModal/types";

interface Props {
  isOpen: boolean;
  product: GetProductResponse;
  onClose: () => void;
}

export const PurchaseModal = ({ isOpen, product, onClose }: Props) => {
  const [currentPage, setCurrentPage] = useState<ModalPage>("selectSchedule");
  const { requestParams, handleParamsChange, handleOnCheckout } =
    useHooks(product);

  // NOTE: モーダルを開いた時 or 商品ページが変更された時にstoreの値とcheckoutを更新する
  useEffect(() => {
    if (isOpen) {
      handleParamsChange({
        product: product.id,
        agreement: false,
      });
    }
  }, [isOpen, product.id]);

  const handleOnClose = useCallback(() => {
    setCurrentPage("selectSchedule");
    onClose();
  }, [onClose]);

  return (
    <Modal
      onClose={handleOnClose}
      size={"5xl"}
      isOpen={isOpen}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      {currentPage === "screening" ? (
        <Screening
          params={requestParams}
          onParamsChange={handleParamsChange}
          onSubmit={handleOnCheckout}
          onBack={() => setCurrentPage("selectSchedule")}
        />
      ) : (
        <SelectSchedule
          product={product}
          params={requestParams}
          onSubmit={handleOnCheckout}
          setCurrentPage={setCurrentPage}
          onParamsChange={handleParamsChange}
          onClose={handleOnClose}
        />
      )}
    </Modal>
  );
};
