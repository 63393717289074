import useSWR from "swr";
import { SERVER_DOMAIN } from "./utils";
import {GetOrderResponseRaw} from "api/dto/OrderResponse";

export const useGetOrder = (sessionId: string, suspense: boolean = false) => {
  const url = `${SERVER_DOMAIN}/api/v1/marvle/order/${sessionId}`;

  const fetcher = (url: string) => {
    return fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    }).then<GetOrderResponseRaw>((res) => res.json());
  };

  const { data, error } = useSWR(url, fetcher, { suspense });

  return {
    data: data?.status === 200 ? data.data : undefined,
    error,
  };
};
