import {
  Box,
  Button,
  Container,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";
import Header from "components/commons/Header";
import Layout from "components/Layout";
import React, { useContext, useEffect, useState } from "react";
import Footer from "components/commons/Footer";
import { FlowSectionCard } from "components/section/RentalGuide/FlowSectionCard";
import { useSearchParams } from "react-router-dom";
import OrderItemCard from "pages/orderSuccess/OrderItemCard";
import { MerchantURLContext } from "./Context";

export const OrderSuccess = () => {
  const [searchParams] = useSearchParams();
  const [merchantShopUrl, setMerchantShopUrl] = useState<string>();

  useEffect(() => {
    const sessionId = searchParams.get("session_id");
    if (sessionId === null) {
      window.location.href = "/404";
    }
  }, [searchParams]);

  return (
    <MerchantURLContext.Provider
      value={{
        merchantUrl: merchantShopUrl,
        setMerchantUrl: setMerchantShopUrl,
      }}
    >
      <Header />
      <Layout zIndex={0}>
        <VStack
          spacing={["24px", "24px", "80px"]}
          align={"center"}
          w={"100%"}
          mt={["40px", "40px", "80px"]}
        >
          <VStack
            spacing={["24px", "24px", "56px"]}
            align={"center"}
            w={"100%"}
          >
            <Heading
              as={"h1"}
              fontSize={"34px"}
              fontWeight={"semibold"}
              color={"text.black"}
            >
              {"レンタル注文が確定しました"}
            </Heading>
            <VStack w={"100%"} spacing={"24px"}>
              <Text color={"pink"} fontSize={"24px"} fontWeight={"semibold"}>
                {"Rental Roomingをお楽しみください！"}
              </Text>
              <Box
                w={["100%"]}
                h={["180px", "212px", "243px"]}
                bgPosition={"center center"}
                bgSize={"cover"}
                bgImage={"/images/purchased/purchased_full.png"}
              />
            </VStack>
            <BackShopButton />
          </VStack>
          <OrderItemCard sessionId={searchParams.get("session_id") as string} />
        </VStack>
      </Layout>
      <Box
        bgColor={"background.green"}
        padding={["40px 24px", "40px 24px", "80px 20px"]}
      >
        <Container maxW="container.lg">
          <FlowSectionCard />
        </Container>
        <Box />
      </Box>
      <Footer />
    </MerchantURLContext.Provider>
  );
};

const BackShopButton = () => {
  const { merchantUrl } = useContext(MerchantURLContext);

  if (merchantUrl === undefined) return null;

  return (
    <Button
      bg={"primary"}
      as={"a"}
      href={merchantUrl}
      color={"white"}
      _hover={{
        opacity: 0.9,
      }}
      _active={{
        opacity: 0.6,
      }}
      size={"lg"}
      fontSize={"16px"}
    >
      {"販売サイトへ戻る"}
    </Button>
  );
};
