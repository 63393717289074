import { Box, Heading } from "@chakra-ui/react";
import Logo from "components/commons/Logo";

export default function Header() {
  return (
    <Box
      height={"60px"}
      display={"flex"}
      alignItems={"center"}
      padding={"16px"}
      boxShadow={"sm"}
    >
      <Heading as="h2" size="xl" aria-label={"Marvle"}>
        <Logo width={186} />
      </Heading>
    </Box>
  );
}
