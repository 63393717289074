import {Flex, Link, Text} from "@chakra-ui/react";
import {ProductCoupon} from "api/dto/ProductResponse";
import Icon from "components/commons/icons";
import {useMemo} from "react";

interface Props {
  coupon: ProductCoupon
}

export const DiscountCard = ({coupon}: Props) => {
  const discount = useMemo(() => {
    if (coupon.discount_unit === "%") {
      return `${coupon.discount_amount}%OFF`;
    } else {
      return `${coupon.discount_amount}円OFF`;
    }
  }, [coupon]);

  return (
   <Flex
     w={"100%"}
     p={"4px 8px"}
     border={"1px solid #E3E8E9"}
     flexDirection={"row"}
     justifyContent={"space-between"}
     alignItems={"center"}
   >
     <Flex flexDirection={"row"} alignItems={"baseline"} gap={"4px"}>
       <Text fontSize={"12px"} fontWeight={"400"} lineHeight={1.5} color={"text.black"}>{"購入時割引"}</Text>
       <Text fontSize={"16px"} fontWeight={"700"} lineHeight={1.5} color={"pink"}>{discount}</Text>
     </Flex>
     <Link
       // /* TODO: Notionのリンクを設定する */
       href={"#rental-guide"}
       display={"inline-flex"}
       alignItems={"center"}
       fontSize={"11px"}
       fontWeight={"700"}
       lineHeight={1.5}
       color={"text.sub"}
     >
       {"詳しく見る"}
       <Icon.LeftArrow size={20} color={"#4A585E"} />
     </Link>
   </Flex>
  );
}
